import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AlgoliaSearchQuery,
  IProductSearchResults,
} from 'src/app/models/product.model';
import environment from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  // Get search value from user input and share with search results page
  public searchValue: any;

  setData(data: any) {
    this.searchValue = data;
  }

  getData() {
    return this.searchValue;
  }

  constructor(private http: HttpClient) {}
  private readonly apiUrl = `https://${environment.algolia.appId}-dsn.algolia.net/1/indexes/${environment.algolia.indexName}`;

  search(searchQuery: AlgoliaSearchQuery): Observable<IProductSearchResults> {
    const headers = {
      'Content-Type': 'application/json',
      'X-Algolia-API-Key': environment.algolia.apiKey,
      'X-Algolia-Application-Id': environment.algolia.appId,
    };
    const body = JSON.stringify(searchQuery);

    return this.http.post(
      this.apiUrl + `/query?region=${environment.stripe.country}`,
      body,
      {
        headers,
      }
    ) as Observable<IProductSearchResults>;
  }
}
