import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { TRequestAddressData } from '@ea/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../storage';
import environment from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  userAddress$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  constructor(private api: ApiService, private storage: LocalStorageService) {}

  createAddress(IRequestAddressData: TRequestAddressData): Observable<any> {
    return this.api.post<any>(
      `/Fabric/Customer/Address/Create?region=${environment.stripe.country}`,
      IRequestAddressData
    );
  }

  getAddress(customId: string): Observable<any> {
    return this.api.get<any>(
      `/Fabric/Customer/Address/Get?CustomerId=${customId}&region=${environment.stripe.country}`
    );
  }

  deleteAddress(addressId: string): Observable<any> {
    const customId = this.storage.getItem('customId');
    const requestBody = {
      addressId: addressId,
      customId: customId,
    };
    return this.api.delete<any>(
      `/Fabric/Customer/Address/Delete?region=${environment.stripe.country}`,
      requestBody
    );
  }

  updateAddress(payload: any): Observable<any> {
    return this.api.patch<any>(
      `/Fabric/Customer/Address/Update?region=${environment.stripe.country}`,
      payload
    );
  }

  getSingleAddress(addressId: string, customId: string): Observable<any> {
    return this.api.get<any>(
      `/Fabric/Customer/Address/Single/Get?addressId=${addressId}&customId=${customId}&region=${environment.stripe.country}`
    );
  }

  storeUserAddress(passedData: any) {
    this.userAddress$.next([]);
    this.userAddress$.next(passedData);
  }
  retrieveUserAddress() {
    return this.userAddress$;
  }
}
