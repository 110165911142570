import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthFacade, AuthService } from '@ea/auth';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'ea-login-by-link',
  templateUrl: './login-by-link.component.html',
  styleUrls: ['./login-by-link.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LoginByLinkComponent implements OnInit {
  registered = false;
  token: string | null = null;
  email: string | null = null;
  uniqueIdentifier: string | null = null;
  error: boolean = false;
  isolating = true;
  constructor(
    private authFacade: AuthFacade,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const queryParams$ = this.route.queryParams;
    queryParams$.subscribe(x =>{
      const confirmCode = x['confirmCode'];
      const callBacks = x['callback'];
      this.route.paramMap.subscribe((paramMap) => {
      
        this.token = paramMap.get('token');
        this.email = paramMap.get('email');
        this.uniqueIdentifier = paramMap.get('uniqueIdentifier');
      });
      if(!confirmCode){
      const payload = {
        token: this.token,
        email: this.email,
        uniqueIdentifier: this.uniqueIdentifier,
      };
      this.authService.confirmMailToken(payload).subscribe(
        (user) => {
          if (user) {
            this.registered = user;
            this.authFacade.loginByLink(user);
          } else {
          }
          this.isolating = false;
        },
        (err) => {
          this.error = err;
        },
      );
      }else{
        const payload = {
          token: this.token,
          email: this.email,
          uniqueIdentifier: this.uniqueIdentifier,
          confirmCode : confirmCode
        };
        this.authService.confirmAccountAndLoginByToken(payload).subscribe(
          (user : any) => {
            if (user) {
              this.registered = user;
              this.authFacade.loginByLink(user, callBacks);
            
            } else {
            }
            this.isolating = false;
          },
          (errs) => {
            this.error = errs;
          },
        );
      }
    })
  
  }
}
