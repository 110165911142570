import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProductSearchResults } from 'src/app/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductDataService {
  products$ = new BehaviorSubject<IProductSearchResults | null>(null);

  setProducts(products: IProductSearchResults) {
    this.products$.next(products);
  }

  getProducts(): Observable<IProductSearchResults | null> {
    return this.products$.asObservable();
  }
}
