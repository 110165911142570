import { ProdEnvironment } from './environment.model';

const environment: ProdEnvironment = {
  countryCodeGBR: 'GBR',
  base: 'uk',
  countryCodeUSA: 'USA',
  production: false,
  usDomainUrl: 'https://enableallfrontenddev.azurewebsites.net/us',
  ukDomainUrl: 'https://enableallfrontenddev.azurewebsites.net/uk',
  serverUrl: 'https://enableallapi.azurewebsites.net',
  fabricMiddlewareTargetApi: 'https://enableallapi.azurewebsites.net/Api',
  fabricPriceTargetApi: 'https://enableallapi.azurewebsites.net/Api',
  fabricPriceListId: {
    priceListId: 100003,
  },
  synerise: {
    collectionId: '65c5ec7b0054d60008c88039',
    indexId: '7641f245fcc540a2fa6f6bd73b0b90431730808518',
    token: '5539a9ac-28d2-45c9-8def-e73abf0f1d36',
    recommendation : "97RKhZL3ZCbw"
  },
  fabricCategoryId: {
    categoryId: '65c5ec7b0054d60008c88039',
  },
  stripe: {
    key: 'pk_test_51KtvmPHhjXma0uG36uK3eeWQy29601FKRKP5yIJr0O0RC07QQm3bEn8s0m3cPqnApUjdMNVQtTW0Ia1UqSTtiMJT00TRL0bmDI',
    country: 'GB',
  },
  algolia: {
    appId: 'CNBQYRJ0X5',
    apiKey: 'b18495cf0455786038a2b9bab38ef452',
    indexName: '63d086cd76f99a0008ea11b9-sandbox-commerce-search-product',
  },
};

export default environment;
