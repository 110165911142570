import { Injectable } from '@angular/core';
import { AuthFacade, CookieService } from '@ea/auth';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  public hubConnection?: signalR.HubConnection;
  public connectionId: string = '';

  constructor(private authFacade: AuthFacade, private cookies: CookieService) {}

  public startConnection = (url: string) => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        withCredentials: false,
      })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    hubConnection
      .start()
      .then(() => this.getConnectionId(hubConnection))
      .then(() => this.addLoginDataListener(hubConnection))
      .catch((err) => console.log('Error while starting connection: ' + err));

    return hubConnection;
  };

  private getConnectionId = (hubConnection: HubConnection) => {
    hubConnection?.invoke('GetConnectionId').then((data) => {
      this.connectionId = data;
    });
  };

  public addLoginDataListener = (hubConnection: HubConnection) => {
    hubConnection.on('LoginByEmail', (data: any) => {
      this.authFacade.loginByLink(data);
      this.endConnection(hubConnection);
    });
    hubConnection.on('LoginByQR', (data: any) => {
      if (data.uniqueIdentifier === this.cookies.get('_snrs_uuid')?.toString())
        this.authFacade.loginByLink(data);
    });
  };

  public endConnection = (hubConnection: HubConnection) => {
    hubConnection.stop().then(() => console.log('Connection ended'));
  };
}
