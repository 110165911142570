import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.interface';

export const selectAuthState = createFeatureSelector<AuthState>('auth');
export const isAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated,
);

export const getState = createFeatureSelector<AuthState>('auth');
export const getUser = createSelector(getState, (state) => state.user);
export const isLoaded = createSelector(getState, (state) => state.isLoaded);
export const isLoading = createSelector(getState, (state) => state.isLoading);
export const isSuccessful = createSelector(
  getState,
  (state) => state.isSuccessful,
);

export const authSelectors = {
  getUser,
  isLoaded,
  isLoading,
  isAuthenticated,
  isSuccessful,
};
