
<div
  class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen"
  *ngIf="this.registered"
>
  <form class="ea-form">
    <fieldset>
      <div class="ea-form">
        <div class="flex flex-col gap-4">
          <div
            class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded">
            <h1 class="text-1xl font-bold font-title mb-4">
              You are logged in
            </h1>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<div
  class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen"
  *ngIf="this.error && !this.isolating"
>
  <form class="ea-form">
    <fieldset>
      <div class="ea-form">
        <div class="flex flex-col gap-4">
          <div
            class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
          >
            <h1 class="text-1xl font-bold font-title mb-4 text-center">
              Please verify the link or your link is expired!
            </h1>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>

