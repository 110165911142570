import { IUser } from '@ea/models';

export interface AuthState {
  user: IUser;
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  isSuccessful: boolean | null;
}

export const INITIAL_STATE: AuthState = {
  user: {
    userId: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: false,
    bearerToken: '',
    customId: '',
    userRegion: '',
  },
  isAuthenticated: false,
  isLoaded: false,
  isLoading: false,
  isSuccessful: null,
};
