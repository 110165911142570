import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  get(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      let ca: Array<string> = document.cookie.split(';');
      let caLen: number = ca.length;
      let cookieName = `${name}=`;
      let c: string;

      for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
          return c.substring(cookieName.length, c.length);
        }
      }
    }
    return null;
  }

  delete(name: string) {
    this.update(name, '', -1);
  }

  update(name: string, value: string, expireDays = 30, path = '/') {
    if (isPlatformBrowser(this.platformId)) {
      let d: Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires: string = `expires=${d.toUTCString()}`;
      let cpath: string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
  }
}
