import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from 'src/app/pages';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    title: "The World's First Truly Accessible Marketplace | EnableAll",
    loadChildren: () =>
      import('../intercom/intercom.module').then((m) => m.IntercomModule),
  },
  {
    path: 'login',
    title: 'Log in | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "login-page" */ './pages/login-page/login-page.component'
      ).then((x) => x.LoginPageComponent),
  },
  {
    path: 'recover',
    title: 'Recover password | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "password-recovery-page" */ './pages/password-recovery/password-recovery-page.component'
      ).then((x) => x.PasswordRecoveryPageComponent),
  },
  {
    path: 'password-reset/:token',
    title: 'Reset password | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "reset-password-page" */ './pages/reset-password/reset-password-page.component'
      ).then((x) => x.ResetPasswordPageComponent),
  },
  {
    path: 'register',
    title: 'Registration | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "register-page" */ './pages/register-page/register-page.component'
      ).then((x) => x.RegisterPageComponent),
  },
  {
    path: 'thank-you',
    title: 'Thank you | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "register-page" */ './pages/ThankYouPage/Thank-you-page.component'
      ).then((x) => x.ThankYouPageComponent),
  },
  {
    path: 'reset-email',
    title: 'Reset email | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "register-page" */ './pages/reset-Email/reset-Email-page.component'
      ).then((x) => x.ResetEmailPageComponent),
  },
  {
    path: 'Email-reset/:token/:new',
    title: 'Reset email confirmation | EnableAll',
    loadComponent: () =>
      import(
        /* webpackChunkName: "register-page" */ './pages/reset-Email-conform/reset-Email-confirm-page.component'
      ).then((x) => x.ResetEmailCinfirmPageComponent),
  },
  {
    path: 'generate-link',
    title: 'Generate login link | EnableAll ',
    loadComponent: () =>
      import(
        /* webpackChunkName: "generate-link-page" */ './pages/generate-login-link/generate-login-link.component'
      ).then((x) => x.GenerateLoginLinkComponent),
  },

  {
    path: 'login-by-link/:token/:email/:uniqueIdentifier',
    title: 'Login by link | EnableAll',
    loadComponent: () =>
      import('./pages/login-by-link/login-by-link.component').then(
        (x) => x.LoginByLinkComponent,
      ),
  },
  {
    path: 'login-by-code',
    title: 'Login by SMS or email code | EnableAll',
    loadComponent: () =>
      import('./pages/login-by-sms/login-by-sms.component').then(
        (x) => x.LoginBySmsComponent,
      ),
  },
  {
    path: 'generate-qr-code',
    title: 'Login by QR-code | EnableAll',
    loadComponent: () =>
      import('./pages/generate-qr-code/generate-qr-code.component').then(
        (x) => x.GenerateQrCodeComponent,
      ),
  },
  {
    path: 'region-redirect/:user',
    loadComponent: () =>
      import('./pages/region-redirect/region-redirect.component').then(
        (x) => x.RegionRedirectComponent,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
