import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, INITIAL_STATE } from './auth.interface';
import { authActions } from './auth.actions';

const reducer = createReducer(
  INITIAL_STATE,

  on(authActions.init, () => INITIAL_STATE),

  on(
    authActions.login,
    authActions.register,
    authActions.generateLink,
    authActions.resetPassword,
    (state) => ({
      ...state,
      isLoaded: false,
      isLoading: true,
    }),
  ),

  on(
    authActions.set,
    authActions.loginSuccess,
    // authActions.updateUserSuccess,
    (state, action) => ({
      ...state,
      user: action.user,
      isAuthenticated: !!action.user?.bearerToken,
    }),
  ),

  on(
    authActions.loginFail,
    authActions.loginSuccess,
    authActions.registerFail,
    authActions.registerSuccess,
    authActions.generateLinkSuccess,
    authActions.generateLinkFail,
    authActions.resetPasswordSuccess,
    authActions.recoverPasswordFail,
    (state) => ({ ...state, isLoaded: true, isLoading: false }),
  ),

  on(authActions.generateLinkSuccess, (state: AuthState) => ({
    ...state,
    isSuccessful: true,
  })),

  on(authActions.generateLinkFail, (state: AuthState) => ({
    ...state,
    isSuccessful: false,
  })),
);

export function authReducer(
  state: AuthState | undefined,
  action: Action,
): AuthState {
  return reducer(state, action);
}
