import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { authActions } from './auth.actions';
import { AuthState } from './auth.interface';
import {
  ILogin,
  IUser,
  OtpLogin,
  Recovery,
  Register,
  ResetData,
  UpdateUser,
} from '@ea/models';
import { authSelectors } from './auth.selector';

@Injectable()
export class AuthFacade {
  user$ = this.store.select(authSelectors.getUser);
  isLoading$ = this.store.select(authSelectors.isLoading);
  isSuccessful$ = this.store.select(authSelectors.isSuccessful);

  constructor(private store: Store<AuthState>) {}

  login(payload: ILogin, redirectRoute: string) {
    this.store.dispatch(authActions.login({ payload, redirectRoute }));
  }

  register(payload: Register) {
    this.store.dispatch(authActions.register({ payload }));
  }

  createAccount(payload: any) {
    this.store.dispatch(authActions.createAccount({ payload }));
  }

  logout(redirectTo: string) {
    this.store.dispatch(authActions.logout({ redirectTo }));
  }
  recover(payload: Recovery) {
    this.store.dispatch(authActions.recoverPassword({ payload }));
  }

  reset(payload: ResetData) {
    this.store.dispatch(authActions.resetPassword({ payload }));
  }

  updateProfile(payload: UpdateUser) {
    this.store.dispatch(authActions.updateProfile({ payload }));
  }

  generateLink(payload: Recovery) {
    this.store.dispatch(authActions.generateLink({ payload }));
  }

  loginByLink(payload: IUser & { uuid: string }, callBack?: string) {
    this.store.dispatch(
      authActions.loginByLink({ payload, redirectRoute: callBack }),
    );
  }

  generateOtp(payload: any) {
    this.store.dispatch(authActions.generateOtp({ payload }));
  }

  loginByCode(payload: OtpLogin) {
    this.store.dispatch(authActions.loginByCode({ payload }));
  }

  loginRegionRedirect(payload: IUser & { uuid: string }) {
    this.store.dispatch(authActions.loginRegionRedirect({ payload }));
  }
}
