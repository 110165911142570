import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthFacade, CookieService } from '@ea/auth';
import { Recovery } from '@ea/models';

@Component({
  selector: 'ea-generate-login-link',
  templateUrl: './generate-login-link.component.html',
  styleUrls: ['./generate-login-link.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
})
export class GenerateLoginLinkComponent {
  isLoading$?: Observable<boolean>;
  isSuccessful$?: Observable<boolean | null>;
  form: FormGroup;

  constructor(
    private builder: FormBuilder,
    private auth: AuthFacade,
    private cookies: CookieService,
  ) {
    this.form = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.isLoading$ = this.auth.isLoading$;
    this.isSuccessful$ = this.auth.isSuccessful$;
  }
  submit() {
    const payload: Recovery = {
      email: this.form.controls['email'].value,
      uniqueIdentifier: this.cookies.get('_snrs_uuid')?.toString(),
    };
    this.auth.generateLink(payload);
  }
}
