import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginByLinkComponent } from './pages/login-by-link/login-by-link.component';
import { GenerateLoginLinkComponent } from './pages/generate-login-link/generate-login-link.component';
import { LoginBySmsComponent } from './pages/login-by-sms/login-by-sms.component';
import { GenerateQrCodeComponent } from './pages/generate-qr-code/generate-qr-code.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthRoutingModule,
    LoginByLinkComponent,
    GenerateLoginLinkComponent,
    LoginBySmsComponent,
    GenerateQrCodeComponent,
  ],
})
export class AuthModule {}
