import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  // Get search value from user input and share with search results page
  public searchValue: any;

  setData(data: any) {
    this.searchValue = data;
  }

  getData() {
    return this.searchValue;
  }
}
