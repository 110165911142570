import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage';
import { CookieService } from '@ea/auth';

declare var Intercom: any;

const INTERCOM_SETTINGS = {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'jogqg6q6',
};

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  customId = this.storage.getItem('customId');
  uuid = this.cookies.get('_snrs_uuid');
  constructor(
    private storage: LocalStorageService,
    private readonly cookies: CookieService
  ) {}

  setIntercomUser(customId: string, uuid: string) {
    (window as any)['Intercom']('update', {
      app_id: INTERCOM_SETTINGS.app_id,
      user_id: customId,
      user_hash: this.uuid,
    });
  }

  loadIntercomScript() {
    (function () {
      const w = window as any;
      const ic = w.Intercom;

      // Define a function to handle Intercom initialization
      function initializeIntercom() {
        const d = document;
        const i = function () {
          i.c(arguments);
        };
        (i as any).q = [];
        i.c = function (args: any) {
          (i as any).q.push(args);
        };
        w.Intercom = i;

        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.defer = true;
          s.async = true;
          s.src =
            'https://widget.intercom.io/widget/' + INTERCOM_SETTINGS.app_id;
          const x = d.getElementsByTagName('script')[0];
          (x as any).parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }

      // Check if Intercom is already initialized
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        // If not, call the initialization function
        initializeIntercom();
      }
    })();
  }

  bootIntercom() {
    if (typeof Intercom !== 'undefined') {
      Intercom('boot', INTERCOM_SETTINGS);
    } else {
      // Intercom script hasn't loaded yet, try again later
      setTimeout(() => this.bootIntercom(), 500);
    }
  }
}
