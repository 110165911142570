import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import environment from 'src/environments/environment';
import { NavCategoryItem } from 'src/app/models/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  baseUrl = '/Collections/';
  constructor(private api: ApiService) {}
  fabricCategoryId = environment.fabricCategoryId;
  rootCategories$ = new BehaviorSubject<NavCategoryItem[]>([]);
  categories$ = new BehaviorSubject<NavCategoryItem[]>([]);

  list(): Observable<NavCategoryItem[]> {
    const body = {};

    return this.getCategory(environment.synerise.collectionId).pipe(
      map((x) => {
        return x.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
      }),
      tap((x) => {
        this.rootCategories$.next(x);
      })
    );
  }

  getCategory(id: string): Observable<NavCategoryItem[]> {
    return this.api.get(
      `${this.baseUrl}${id}?region=${environment.stripe.country}`
    );
  }

  getCollections(names: string) {
    const body = {};
    return this.api.get(
      `${this.baseUrl}names/${names}?region=${environment.stripe.country}`
    );
  }
}
