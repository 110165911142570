<div class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen">
  <form *ngIf="!isCodeSent" [formGroup]="this.form" class="ea-form bg-theme-surface">
    <fieldset>
      <div class="flex flex-col gap-4">
        <legend>
          <h1 class="text-2xl font-bold font-title mb-4">
            Generate code for logging in
          </h1>
          <div>
            <mat-button-toggle-group
              formControlName="contactType"
              name="contentType"
              aria-label="Font Style"
            >
              <mat-button-toggle
                value="email"
                class="ea-button   choose-btn"
                >Email</mat-button-toggle
              >
              <mat-button-toggle
                value="phone"
                class="ea-button  choose-btn"
                >Phone</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>

          <div *ngIf="form.get('contactType')?.value == 'email'">
            <p class="text-base my-4 text-theme-on-surface">
              Please enter the email you registered with below. You will receive
              an email with a unique code for logging in.
            </p>
            <div
              class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
            >
              <div class="ea-form-field">
                <label for="email-input" class="ea-label required"
                  >Email address</label
                >
                <input
                  id="email-input"
                  formControlName="email"
                  name="email"
                  class="ea-input"
                  type="email"
                  placeholder="Email address"
                  autocomplete="email"
                  alt="email input"
                />
              </div>

              <input
                type="submit"
                class="ea-button ea-button-primary w-full"
                value="Submit"
                title="Submit"
                alt="submit"
                (click)="submit()"
                [disabled]="
                  form.invalid ||
                  !form.get('email')?.value ||
                  (isLoading$ | async) === true
                "
              />
            </div>
          </div>

          <div *ngIf="form.get('contactType')?.value == 'phone'">
            <p class="text-base my-4 text-theme-on-surface">
              Please enter the phone number you registered with below. You will
              receive an message with a unique code for logging in.
            </p>
            <div
              class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
            >
              <div class="ea-form-field">
                <label for="email-input" class="ea-label required"
                  >Phone number</label
                >
                <ngx-intl-tel-input
                  [cssClass]="'custom'"
                  [preferredCountries]="preferredCountries"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.UnitedKingdom"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.National"
                  name="phone"
                  alt="phone input"
                  formControlName="phone"
                >
                </ngx-intl-tel-input>
              </div>

              <input
                type="submit"
                class="ea-button ea-button-primary w-full"
                value="Submit"
                alt="submit"
                title="Submit"
                (click)="submit()"
                [disabled]="
                  !form.get('phone')?.value || (isLoading$ | async) === true
                "
              />
            </div>
          </div>
        </legend>
      </div>
    </fieldset>
  </form>

  <div *ngIf="isCodeSent" class="border-right-gray col-sm-12">
    <h1 class="font-bold font-title">Log in</h1>
    <form
      [formGroup]="codeForm"
      class="rounded ea-form bg-theme-surface"
      (ngSubmit)="login()"
    >
      <div class="form-group mt-4">
        <label class="form-label ea-label required" for="code">Code</label>

        <div class="input-group">
          <input
            type="text"
            placeholder="Code"
            class="ea-input form-control-flex focus-ring"
            id="code"
            formControlName="code"
            alt="code input"
          />
        </div>
        <button
          type="submit"
          class="ea-button ea-button-primary col-sm-12 p-2 my-3 text-uppercase focus-ring"
          [disabled]="codeForm.invalid"
        >
          <strong>Log in</strong>
        </button>
        <p class="text-center text-theme-on-surface">
          Try to send code one more time
          <a class="resend" (click)="sendCodeAgain()">Send again</a>
        </p>
      </div>
    </form>
  </div>
</div>
