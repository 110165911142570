import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { IOrder, ReturnItemsRequest } from '@ea/models';
import environment from 'src/environments/environment';
import { LocalStorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseUrl = '/Orders/';
  constructor(private api: ApiService, private storage: LocalStorageService) {}

  getOrderByCustomerId(customId: string): Observable<any> {
    const bearerToken = this.storage.getItem('bearerToken');
    const userId = this.storage.getItem('user').userId;
    return this.api.get<any>(
      `${this.baseUrl}Get/OrderByCustomerId/${userId}?customerId=${customId}&region=${environment.stripe.country}`
    );
  }

  getOrderById(orderId: string): Observable<any> {
    return this.api.get<any>(
      `${this.baseUrl}Get/OrderById?orderId=${orderId}&region=${environment.stripe.country}`
    );
  }


  getReasoens(): Observable<any> {
    return this.api.get<any>(
      `${this.baseUrl}Get/return-reasoens?region=${environment.stripe.country}`
    );
  }

  returnAllItems(
    orderId: string,
    order: IOrder,
    SelectedReason : string
  ): Observable<IOrder> {
    return this.api.post(
      `${this.baseUrl}Return/All?orderId=${orderId}&reasoneCode=${SelectedReason}&region=${environment.stripe.country}`,
      order
    );
  }

  getReturnEligibility(orderId: string): Observable<any> {
    return this.api.get<any>(
      `${this.baseUrl}ReturnEligibility/Get?orderId=${orderId}&region=${environment.stripe.country}`
    );
  }
}
