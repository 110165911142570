import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private api: ApiService) {}
  postData(formData: any) {
    const url = '/webhooks/contactus';
    return this.api.post(url, formData);
  }
}
