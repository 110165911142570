<div class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen">
  <div *ngIf="!(isSuccessful$ | async)">
    <form [formGroup]="this.form" class="ea-form bg-theme-surface">
      <fieldset>
        <div class="flex flex-col gap-4">
          <legend>
            <h1 class="text-2xl font-bold font-title mb-4">
              Generate unique login link
            </h1>

            <p class="text-base text-theme-on-surface">
              Please enter the email you registered with below. You will receive
              an email with a unique link for logging in.
            </p>
          </legend>

          <div
            class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
          >
            <div class="ea-form-field">
              <label for="email-input" class="ea-label required"
                >Email address</label
              >
              <input
                id="email-input"
                formControlName="email"
                name="email"
                class="ea-input"
                type="email"
                placeholder="Email address"
                autocomplete="email"
                alt="email input"
              />
            </div>

            <input
              type="submit"
              class="ea-button ea-button-primary w-full"
              value="Submit"
              title="Submit"
              (click)="submit()"
              [disabled]="form.invalid || (isLoading$ | async)"
              alt="submit"
            />
          </div>
        </div>
      </fieldset>
    </form>
  </div>

  <div *ngIf="isSuccessful$ | async">
    <h2 class="text-2xl font-bold font-title mb-4">Thank You!</h2>
    <p>
      We have sent an email to you. Please check and follow the instructions to login
    </p>
  </div>
</div>
