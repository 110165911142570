import { AfterContentChecked, Component } from '@angular/core';
import { CookieService } from '@ea/auth';
import { QRCodeModule } from 'angularx-qrcode';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from '@ea/services';

@Component({
  selector: 'ea-generate-qr-code',
  templateUrl: './generate-qr-code.component.html',
  styleUrls: ['./generate-qr-code.component.scss'],
  standalone: true,
  imports: [QRCodeModule, CommonModule],
})
export class GenerateQrCodeComponent implements AfterContentChecked {
  qrData: string = '';

  constructor(
    private cookies: CookieService,
    private storage: LocalStorageService,
  ) {
    this.generateQrcode();
  }
  ngAfterContentChecked() {
    if (this.qrData == '') {
      this.generateQrcode();
    }
  }
  generateQrcode() {
    this.qrData = this.cookies.get('_snrs_uuid')?.toString() || '';
  }
}
