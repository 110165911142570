import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import environment from 'src/environments/environment';
import { LocalStorageService } from '../storage/local-storage.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

type QueryParameters = {
  [param: string]:
    | string
    | number
    | boolean
    | readonly (string | number | boolean)[];
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  fabricApiUrl = environment.fabricMiddlewareTargetApi;
  private get headers() {
    if (isPlatformBrowser(this.platformId)) {
      const bearerToken = this.storage.getItem('bearerToken');
      return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${bearerToken}`,
      };
    }
    return undefined;
  }

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  get<T>(path: string, query: QueryParameters = {}): Observable<T> {
    return this.http
      .get<T>(`${this.fabricApiUrl}${path}`, {
        params: query,
        observe: 'response',
        headers: this.headers,
      })
      .pipe(
        catchError((err) => {
          if(err.status == 404){
            this.router.navigate(['/']);
            }
      
          throw err;

        }),
        map((x) => {
          return x.body!;
        })
      );
  }

  put<T>(path: string, body: Record<string, unknown>) {
    return this.http.put<T>(
      `${this.fabricApiUrl}${path}`,
      JSON.stringify(body),
      {
        headers: this.headers,
      }
    );
  }

  post<T>(path: string, body: Record<string, unknown>) {
    return this.http.post<T>(
      `${this.fabricApiUrl}${path}`,
      JSON.stringify(body),
      {
        headers: this.headers,
      }
    );
  }

  delete<T>(path: string, body?: Record<string, unknown>) {
    return this.http.delete<T>(`${this.fabricApiUrl}${path}`, {
      headers: this.headers,
      ...(body && { body: JSON.stringify(body) }),
    });
  }

  patch<T>(
    path: string,
    body: Record<string, unknown>,
    query: QueryParameters = {}
  ) {
    return this.http.patch<T>(
      `${this.fabricApiUrl}${path}`,
      JSON.stringify(body),
      {
        headers: this.headers,
        params: query,
      }
    );
  }
}
