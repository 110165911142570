import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthFacade } from '@ea/auth';
import {
  CountryISO,
  NgxIntlTelInputModule,
  PhoneNumberFormat,
  SearchCountryField,
} from '@justin-s/ngx-intl-tel-input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ea-login-by-sms',
  templateUrl: './login-by-sms.component.html',
  styleUrls: ['./login-by-sms.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonToggleModule,
    NgxIntlTelInputModule,
    CommonModule,
  ],
})
export class LoginBySmsComponent {
  isLoading$?: Observable<boolean>;
  form: FormGroup;
  codeForm: FormGroup;
  isCodeSent: boolean = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  constructor(
    private builder: FormBuilder,
    private auth: AuthFacade,
  ) {
    this.form = this.builder.group({
      email: ['', Validators.email],
      contactType: ['email'],
      phone: [undefined],
    });

    this.codeForm = this.builder.group({
      code: ['', [Validators.required]],
    });
  }

  submit() {
    let payload = {
      contactValue: '',
      contactType: this.form.value.contactType,
    };
    if (this.form.value.contactType == 'email') {
      payload.contactValue = this.form.value.email;
    } else {
      payload.contactValue = this.form.value.phone.e164Number;
    }

    this.auth.generateOtp(payload);
    this.isCodeSent = true;
  }

  login() {
    let contactValue =
      this.form.value.contactType === 'email'
        ? this.form.value.email
        : this.form.value.phone.e164Number;

    this.auth.loginByCode({
      contactValue: contactValue.trim(),
      contactType: this.form.value.contactType,
      otp: this.codeForm.value.code.trim(),
    });
  }

  sendCodeAgain() {
    this.isCodeSent = false;
  }
}
