<div class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen text-center">
<h1 class="font-bold my-3">Login by QR-code</h1>
<div>Please scan the code with your mobile camera to log in</div>

<div class="qrcodeImage mt-4">
  <qrcode
    [qrdata]="qrData"
    [elementType]="'img'"
    [width]="256"
    [errorCorrectionLevel]="'M'"
    [allowEmptyString]="true"
    [alt]="'QR code for the event'"

  ></qrcode>
</div>

</div>
