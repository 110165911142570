import { Injectable } from '@angular/core';
import environment from 'src/environments/environment';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class AutoCompleteService {
  fabricPriceListId = environment.fabricPriceListId.priceListId;
  countryCodeUSA = environment.countryCodeUSA;
  countryCodeGBR = environment.countryCodeGBR;

  constructor(private api: ApiService) {}

  getAutoCompleteSuggestions(Search: string, Country: string) {
    const url = `/Experian/Address/Autocomplete?Search=${Search}&Country=${Country}&region=${environment.stripe.country}`;
    return this.api.post(url, {});
  }

  getAutoComplete(globalKey: string) {
    return this.api.get(
      `/Experian/Address/Serach/Format?region=${environment.stripe.country}`,
      {
        GlobalKey: globalKey,
      },
    );
  }
}
